import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { toJS } from "mobx";
import SubjectEnrollmentComponent from "../../components/enrollment-component/subject-enrolment";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const StudentEnrollmentPage = observer(() => {
  const { rootStore, calendarStore, studentStore, academicStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [semester, setSemester] = useState<string>("0");
  const [homeRoomCode, setHomeRoomCode] = useState<string>("");
  const [yearLevel, setYearLevel] = useState<string>("0");
  const [targetStudent, setTargetStudent] = useState<any>();
  const [stuId, setStuId] = useState<string>("0");
  const [targetStudentSem, setTargetStudentSem] = useState<any>();
  // Used in display class table
  const [sIdForClassTable, setSIdForClassTable] = useState<number>(0);
  const [semInfoForClassTable, setSemInfoForClassTable] = useState<string>("");
  const [enrolledSemId, setEnrolledSemId] = useState<number>(0);
  const onChangeSemester = (event: SelectChangeEvent) => {
    setSemester(event.target.value as string);
  };
  const onChangeYearLevel = (event: SelectChangeEvent) => {
    setYearLevel(event.target.value as string);
  };
  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) != studentStore.studentProfileList.data.length) {
      studentStore.getStudentProfileList(0, 0);
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) != calendarStore.calendarSettingList.data.length) {
      calendarStore.getCalendarSettingList(0, 0);
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.YEAR_LEVEL) == 0) {
      academicStore.getYearLevelList();
    }

    // if (ApiDataResponseListLengthCheck(ApiDataListType.ACADEMIC) == 0 ||
    //   ApiDataResponseListLengthCheck(ApiDataListType.ACADEMIC) != academicStore.academicSettingList.data.length) {
    //   academicStore.getAcademicSettingList(0, 0);
    // }
  }, []);

  const onChangeStudent = (event: SelectChangeEvent) => {
    let id = event.target.value;
    setSIdForClassTable(0);
    setStuId(id);
    if (id === "0") setTargetStudent(null)
    else {
      studentStore.getStudentProfileById(+id)
        .then((response) => {
          setTargetStudent(response);
        })
    }
  };

  const onActionStudentSemester = () => {
    let req: any = {
      id: 0,
      studentId: targetStudent.id,
      semesterId: semester,
      homeRoomCode,
      yearLeveLId: yearLevel,
    }
    if (targetStudentSem) {
      req.id = targetStudentSem.id;
      studentStore.updateStudentSemester(req).then(() => {
        rootStore.notify(t('STUDENT_SEMESTER') + t('UPDATED_B'), 'success');
        onRefreshPage();
        handleCloseModal();
      }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
    } else {
      studentStore.enrollStudentSemester(req).then(() => {
        rootStore.notify(t('STUDENT_SEMESTER') + t('CREATED_B'), 'success')
        onRefreshPage();
        handleCloseModal();
      }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
    }
  }

  const onRefreshPage = () => {
    studentStore.getStudentProfileById(targetStudent.id)
      .then((response) => {
        setTargetStudent(response);
      });
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = targetStudent.studentSemesters.find(ss => ss.id == id);
      setTargetStudentSem(target);
      setSemester(target.semesterId);
      setHomeRoomCode(target.homeRoomCode);
      setYearLevel(target.yearLevelId);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setSemester("0");
    setHomeRoomCode("");
    setYearLevel("0");
    setTargetStudentSem(null);
  }

  const handleDeleteModal = (id: number) => {
    let target = targetStudent.studentSemesters.find(ss => ss.id == id);
    setTargetStudentSem(target);
    setShowDeleteModal(true);
  }

  const onCheckValidSemesters = (semesterId: number) => {
    // if is in update mode
    if (targetStudentSem) {
      if (targetStudentSem.semesterId == semesterId ||
        !targetStudent.studentSemesters.map(ss => ss.semesterId)
          .includes(semesterId)) return true;
      return false;
    } else {
      if (targetStudent && !targetStudent.studentSemesters.map(ss => ss.semesterId)
        .includes(semesterId)) return true;
      return false;
    }
  }

  return (
    <ContentLayout
      pageName={t('ENROLLMENT')}
      pageHeading={t('ENROLLMENT')}
      breadCrumb={[
        { label: t('STUDENT_PROFILE') + t('SETTING_B'), to: "" },
        { label: t('ENROLLMENT'), to: "/teacher/enrollment" }
      ]}
      showHeadingButton={false}
    >
      <div className="mb-4">
        {studentStore.studentProfileList.data.length > 0 &&
          <FormControl fullWidth sx={{ with: 300 }}>
            <InputLabel>{t('SELECT_STUDENT')}</InputLabel>
            <Select
              label={t('SELECT_STUDENT')}
              sx={{ width: 300, background: 'white' }}
              value={stuId}
              onChange={onChangeStudent}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {studentStore.studentProfileList.data.map((stu: any, index: number) => (
                <MenuItem key={`stu_select_${index}`} value={stu.id}>
                  {stu.firstName + " " + stu.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
      </div>
      <Typography variant="h6" fontWeight={'bold'} marginBottom={1}>{t('STUDENT_PROFILE')}</Typography>
      {
        studentStore.loading ?
          <>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
          </>
          : stuId === "0" || ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) == 0 || !targetStudent
            ? <div className="text-center"><Typography variant="h6">{t('NO_STUDENT_SELECTED')}</Typography></div>
            : <>
              <div className="grid grid-cols-4 gap-4 bg-white p-8 rounded-lg">
                <TextField
                  value={targetStudent.title}
                  label={t('TITLE')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={targetStudent.username}
                  label={t('USERNAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={targetStudent.firstName}
                  label={t('FIRST_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={targetStudent.lastName}
                  label={t('LAST_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={targetStudent.middleName}
                  label={t('MIDDLE_NAME')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                />
                <TextField
                  value={targetStudent.birthDate.split('T')[0]}
                  label={t('BOD')}
                  type="date"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              {/* Enrol in Semester */}
              <div className="flex justify-between mt-4">
                <Typography variant="h6" fontWeight={'bold'} marginBottom={3}>{t('ENROLLED_SEMESTERS')}</Typography>
                <VmButton
                  className="bg-highlight text-white px-4 py-2"
                  style={{ height: 'fit-content' }}
                  onClick={() => handleShowModal()}
                >
                  {t('ENROLL_STUDENT_SEMESTER')}
                </VmButton>
              </div>
              <VmTable
                loading={studentStore.loading}
                thead={["ID", t('SEMESTER'), t('YEAR_LEVEL'), t('ACTION')]}
                tbody={targetStudent.studentSemesters.length > 0 ?
                  targetStudent.studentSemesters.map((student, index) => (
                    <>
                      <Box marginY={1} />
                      <tr key={`student_${index}`}>
                        <td className={TD_FIRST}>{index + 1}</td>
                        {/* <td className={TD_NORMAL}>{student.homeRoomCode}</td> */}
                        <td className={TD_NORMAL}>
                          {calendarStore.calendarSettingList.data.length > 0 &&
                            calendarStore.calendarSettingList.data.map((cYear: any) => (
                              cYear.schoolSemesters.filter((ss: any) => ss.id == student.semesterId)
                                .map((ss: any) => (
                                  cYear.yearDescription + " " + ss.semesterDescription
                                ))
                            ))}
                        </td>
                        <td className={TD_NORMAL}>
                          {academicStore.yearLevelList.length > 0 &&
                            academicStore.yearLevelList
                              .find(yl => yl.id == student.yearLevelId).yearLevelDescription}
                        </td>
                        <td className={TD_LAST}>
                          <Button
                            variant="outlined"
                            sx={{ marginRight: 2 }}
                            onClick={() => handleShowModal(student.id)}
                          >
                            {t('EDIT')}
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{ marginRight: 2 }}
                            onClick={() => {
                              setSemInfoForClassTable(calendarStore.calendarSettingList.data.length > 0 &&
                                calendarStore.calendarSettingList.data.map((cYear: any) => (
                                  cYear.schoolSemesters.filter((ss: any) => ss.id == student.semesterId)
                                    .map((ss: any) => (
                                      cYear.yearDescription + " " + ss.semesterDescription
                                    ))
                                )));
                              setEnrolledSemId(student.semesterId);
                              setSIdForClassTable(student.id);
                            }}
                          >
                            {t('VIEW_ENROLLED_CLASS_BY_SEM')}
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteModal(student.id)}
                          >
                            {t('DELETE')}
                          </Button>
                        </td>
                      </tr>
                      <Box marginY={1} />
                    </>
                  ))
                  : <tr><td className="text-center py-4" colSpan={5}>{t('NO_RESULT_FOUND')}</td></tr>}
              />
              {/* Enrol in Student Class */}
              {
                sIdForClassTable != 0 &&
                <Box marginTop={1}>
                  <SubjectEnrollmentComponent
                    targetStudent={targetStudent}
                    targetSemester={semInfoForClassTable}
                    targetSemesterId={enrolledSemId}
                    id={sIdForClassTable}
                    onReload={onRefreshPage}
                  />
                </Box>
              }
            </>
      }

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetStudentSem ? t('UPDATE_A') + t('STUDENT_SEMESTER') : t('ADD_A') + t('STUDENT_SEMESTER')}
        buttonLabel={targetStudentSem ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetStudentSem ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionStudentSemester}
        disabledCondition={semester === "0" || yearLevel === "0"}
      >
        <Typography variant={'subtitle1'} mb={2} style={{ marginTop: -20 }}>
          {t('STUDENT_SINGLE')}: {targetStudent && targetStudent.firstName + " " + targetStudent.lastName}
        </Typography>
        <FormControl fullWidth>
          <InputLabel>{t('SEMESTER')}</InputLabel>
          {
            calendarStore.calendarSettingList.data.length > 0 &&
            <Select
              label={t('SEMESTER')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={semester}
              onChange={onChangeSemester}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {calendarStore.calendarSettingList.data.map((cYear: any) => (
                // only show sem that is not enrolled and the current one
                cYear.schoolSemesters.map((cSemester: any, index: number) => (
                  onCheckValidSemesters(cSemester.id) &&
                  <MenuItem key={`semester_select_${index}`} value={cSemester.id}>
                    {cYear.yearDescription + " S" + cSemester.semesterInt}
                  </MenuItem>
                ))
              ))}
            </Select>
          }
        </FormControl>
        {/* <TextField
          value={homeRoomCode}
          label={t('CLASS_CODE')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setHomeRoomCode(value.target.value)}
        /> */}
        <FormControl fullWidth>
          <InputLabel>{t('YEAR_LEVEL')}</InputLabel>
          {
            academicStore.yearLevelList.length > 0 &&
            <Select
              label={t('YEAR_LEVEL')}
              sx={{ width: '100%' }}
              value={yearLevel}
              onChange={onChangeYearLevel}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.yearLevelList.map((yl: any, index: number) => (
                <MenuItem key={`year_level_select_${index}`} value={yl.id}>Year {yl.yearLevelInt}</MenuItem>
              ))}
            </Select>
          }
        </FormControl>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => studentStore.deleteStudentSemester(targetStudentSem.id).then(() => {
          rootStore.notify(t('STUDENT_SEMESTER') + t('DELETED_B'), 'success');
          onRefreshPage();
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_ENROLLMENT')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default StudentEnrollmentPage;