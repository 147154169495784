import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Switch, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import ClearIcon from '@mui/icons-material/Clear';
import { toJS } from "mobx";
import { t } from "i18next";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const SubjectEnrollmentComponent = observer(({ targetSemester = "", targetSemesterId = 0, targetStudent, id, onReload }) => {
  const { rootStore, studentStore, academicStore } = useStores();
  const [targetStudentSemList, setTargetStudentSemList] = useState<any>(targetStudent.studentSemesters.find(ss => ss.id == id));
  const [enrolList, setEnrolList] = useState<any>([]);
  const [activeFunction, setActiveFunction] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteSemesterId, setDeleteSemesterId] = useState<number>(0);
  useEffect(() => {
    if (targetSemesterId != 0) academicStore.getAcademicSettingListBySemesterId(targetSemesterId, 0, 0)
    setTargetStudentSemList(targetStudent.studentSemesters.find(ss => ss.id == id));
  }, [targetStudent, id]);

  const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>, idInfo: any) => {
    let tempEnrolList = enrolList;
    tempEnrolList.find(e => e.domainId == idInfo.domainId)
      .subjectStatusList.find(s => s.subjectId == idInfo.subjectId).active = event.target.checked;
    setEnrolList(tempEnrolList);
    setActiveFunction([]);
  };

  const onChangeSubjectClass = (event: SelectChangeEvent, idInfo: any) => {
    let tempEnrolList = enrolList;
    tempEnrolList.find(e => e.domainId == idInfo.domainId)
      .subjectStatusList.find(s => s.subjectId == idInfo.subjectId).subjectClassId = event.target.value as string;
    setEnrolList(tempEnrolList);
    setActiveFunction([]);
  };

  const onChangeDomain = (event: SelectChangeEvent, index: number) => {
    let tempEnrolList = enrolList;
    let id = event.target.value as string;
    if (tempEnrolList.find(e => e.domainId == id)) {
      return;
    }
    tempEnrolList[index].domainId = id;
    if (id !== "0") {
      let tempSubjectList = academicStore.academicSettingBySemList.data.find(d => d.id == +id).subjects;
      if (tempSubjectList.length > 0) {
        let enrolledSubjectId: any[] = [];
        for (var ssc of targetStudentSemList.studentSemesterClasses) {
          enrolledSubjectId.push(ssc.academicSubjectClass.subjectId);
        }
        tempEnrolList[index].subjectList = tempSubjectList;
        tempEnrolList[index].subjectStatusList = tempSubjectList
          .map((s) => ({
            subjectId: s.id,
            subjectClassId: "0",
            active: enrolledSubjectId.length > 0 && enrolledSubjectId.includes(s.id) ? false : true,
            enrolled: enrolledSubjectId.length > 0 && enrolledSubjectId.includes(s.id) ? true : false
          }));

      } else {
        tempEnrolList[index].subjectStatusList = [];
        tempEnrolList[index].subjectList = [];
      }
    } else {
      tempEnrolList[index].subjectStatusList = [];
      tempEnrolList[index].subjectList = [];
    }
    setEnrolList(tempEnrolList);
    setActiveFunction([]);
  };

  const onEnrolToClass = () => {
    let reqList: any[] = [];
    for (var domain of enrolList) {
      for (var subject of domain.subjectStatusList) {
        if (subject.active) {
          if (subject.subjectClassId == "0") {
            rootStore.notify(t('NO_CLASS_SELECTED_FOR_AT_LEAST_ONE_SUBJECT'), 'warning');
            return;
          }
          reqList.push({
            studentSemesterId: targetStudentSemList.id,
            academicSubjectClassId: subject.subjectClassId,
          });
        }
      }
    }
    if (reqList.length == 0) {
      rootStore.notify(t('NO_SUBJECTS_IS_FOUND_IN_SELECTED_DOMAIN'), 'warning');
      return;
    }
    console.log(reqList);
    studentStore.enrollStudentClass(reqList).then(() => {
      rootStore.notify(t('ENROLL_CLASS') + t('UPDATED_B'), 'success');
      onReload();
    }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
  }

  const handleShowModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteSemesterId(id);
  }

  return (
    <>
      <div className="flex justify-between mt-4">
        <Typography variant="h6" fontWeight={'bold'} marginBottom={3}>{t('ENROLLED_CLASSES')}: {targetSemester}</Typography>
        <VmButton
          className="bg-highlight text-white px-4 py-2"
          style={{ height: 'fit-content' }}
          onClick={() => {
            let tempList = enrolList;
            tempList.push({
              domainId: "0",
              studentSemesterId: id,
              subjectList: [],
              subjectStatusList: [],
            })
            console.log("list", tempList);
            setEnrolList(tempList);
            setActiveFunction([]);
          }}
        >
          {t('ENROLL_CLASS')}
        </VmButton>
      </div>

      <div className="border">
        <VmTable
          loading={studentStore.loading}
          thead={["ID", t('CLASS_CODE'), t('CLASS_IDENTIFIER'), t('ACTION')]}
          tbody={targetStudent.studentSemesters.length > 0 ?
            targetStudentSemList.studentSemesterClasses.map((ssc: any, index: number) => (
              <>
                <Box marginY={1} />
                <tr key={`student_semester_class_${index}`}>
                  <td className={TD_FIRST}>{index + 1}</td>
                  <td className={TD_NORMAL}>{ssc.academicSubjectClass.classCode}</td>
                  <td className={TD_NORMAL}>{ssc.academicSubjectClass.classIdentifier}</td>
                  <td className={TD_LAST}>
                    <Button
                      variant="outlined"
                      onClick={() => handleShowModal(ssc.id)}
                      color="error"
                    >
                      {t('DELETE')}
                    </Button>
                  </td>
                </tr>
                <Box marginY={1} />
              </>
            )) : <Typography textAlign="center">{t('NO_ENROLLMENTS_FOUND')}</Typography>}
        />
      </div>

      <Divider sx={{ marginY: 2 }} />
      {enrolList.length > 0 && <Typography variant="h6" fontWeight={'bold'} marginBottom={1}>
        {t('ENROLLED_COURSE_INFO')}: {targetStudentSemList && targetStudentSemList.homeRoomCode}
      </Typography>}
      <div className="bg-white p-4 rounded-lg">
        {enrolList.map((value: any, index: number) => (
          <>
            <div className="grid grid-cols-5 gap-4">
              <div className="flex">
                {academicStore.academicSettingBySemList.data.length > 0 ?
                  <FormControl fullWidth>
                    <InputLabel>{t('DOMAIN')}</InputLabel>
                    <Select
                      labelId={`id_${index}`}
                      id={`id_${index}`}
                      label={t('DOMAIN')}
                      sx={{ width: '100%' }}
                      value={enrolList[index].domainId}
                      onChange={(event) => onChangeDomain(event, index)}
                    >
                      <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                      {academicStore.academicSettingBySemList.data.map((domain: any, index: number) => (
                        <MenuItem key={`domain_select_${index}`} value={domain.id}>{domain.domainCode}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  : <CircularProgress size={10} />}
              </div>
              {/* <div className="col-span-3"></div>
              <div className="flex justify-end">
                <VmButton
                  className="bg-highlight text-white mt-2 p-2 pr-4"
                  style={{ height: 'fit-content', }}
                  onClick={() => {
                    if (enrolList.length > 1) {
                      let tempEnrolList = enrolList;
                      tempEnrolList.splice(index, 1);
                      setEnrolList(tempEnrolList);
                    }
                    setActiveFunction([]);
                  }}
                >
                  <ClearIcon />
                  {t('REMOVE')}
                </VmButton>
              </div> */}
            </div>
            <Box sx={{ marginY: 2 }} />
            {value.subjectList.length > 0 ?
              value.subjectList.map((subject: any, index: number) => (
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    <FormControl fullWidth>
                      <InputLabel>{t('UNIT_CODE')} {subject.compulsory && `(${t('COMPULSORY')})`}</InputLabel>
                      <Select
                        labelId={`id_${index}`}
                        id={`id_${index}`}
                        label={subject.compulsory ? `${t('UNIT_CODE')} (${t('COMPULSORY')})` : `${t('UNIT_CODE')}`}
                        sx={{ width: '100%' }}
                        value={subject.id}
                        disabled={true}
                      >
                        <MenuItem key={`subject_select_${index}`} value={subject.id}>{subject.subjectCode}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex">
                    {
                      value.subjectStatusList.length > 0 &&
                      <>
                        <FormControl fullWidth>
                          <InputLabel>{t('CLASS_CODE')}</InputLabel>
                          <Select
                            labelId={`id_${index}`}
                            id={`id_${index}`}
                            label={t('CLASS_CODE')}
                            sx={{ width: '100%' }}
                            value={value.subjectStatusList.find(s => s.subjectId == subject.id).subjectClassId}
                            onChange={(event) => onChangeSubjectClass(event, { subjectId: subject.id, domainId: value.domainId })}
                            disabled={value.subjectStatusList.find(s => s.subjectId == subject.id).enrolled
                              || !value.subjectStatusList.find(s => s.subjectId == subject.id).active
                            }
                          >
                            {value.subjectStatusList.find(s => s.subjectId == subject.id).enrolled ?
                              <MenuItem value={"0"}>{t('SUBJECT_IS_ENROLLED')}</MenuItem> :
                              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>}
                            {subject.academicSubjectClasses.length > 0 &&
                              subject.academicSubjectClasses.map((sClass: any, index: number) => (
                                <MenuItem key={`sClass_select_${index}`} value={sClass.id}>{sClass.classCode}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <Switch
                          sx={{ marginTop: 1 }}
                          disabled={subject.compulsory || value.subjectStatusList.find(s => s.subjectId == subject.id).enrolled}
                          checked={value.subjectStatusList.find(s => s.subjectId == subject.id).enrolled ? false
                            : value.subjectStatusList.find(s => s.subjectId == subject.id).active}
                          onChange={(event) => onChangeStatus(event, { subjectId: subject.id, domainId: value.domainId })}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </>
                    }

                  </div>
                </div>
              ))
              : <Typography>{t('NO_SUBJECT_FOUND')}</Typography>}
            <Divider sx={{ marginY: 2 }} />
          </>
        ))}
        {enrolList.length > 0 && <div className="text-right mt-4">
          <VmButton
            className="bg-highlight text-white px-4 py-2"
            style={{ height: 'fit-content' }}
            onClick={onEnrolToClass}
          >
            {t('ADD_NOW')}
          </VmButton>
        </div>}
      </div>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => studentStore.deleteStudentClass(deleteSemesterId).then(() => {
          rootStore.notify(t('ENROLL_CLASS') + t('DELETED_B'), 'success')
          setShowDeleteModal(false);
          setDeleteSemesterId(0);
          onReload();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_SUBJECT_ENROLLMENT')}
        </Typography>
      </VmModal>
    </>
  )
});

export default SubjectEnrollmentComponent;
